import jsonMuyBarato from "../../Info/miembrosMuyBarato.json";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";

export default function Details() {
    let { id } = useParams();
    const [jsonKeys, setJsonKeys] = useState([]);

    useEffect(() => {
        setJsonKeys(Object.keys(jsonMuyBarato));
    }, []);

    return (
         <>
         <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
         </head>
            {jsonKeys?.includes(id) ? (
                <table width={'100%'} style={{  background: 'transparent', overflow: 'hidden', maxHeight: '160px', maxWidth:'320px' }}>
                    <tbody>
                        <tr>
                            
                            <td style={{ textAlign: 'center', height: '160px', width: '100%', maxWidth: '320px', padding: '0px', border: 'none' }}>
                                <table width="320px" height="120px" style={{  background:'url(https://muybaratomayorista.com.ar/wp-content/uploads/firmas/img/Fondofirma.gif)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                    <tbody>
                                        <tr style={{ maxHeight: '75px' }}>
                                            <td style={{ padding: '31px 2% 0 31%', textAlign: 'left', width: '80%' }}>
                                                <p style={{ fontSize: '97%', color: 'rgb(245, 130, 32)', textTransform: 'uppercase', fontFamily: 'Arial, sans-serif', margin: '0', fontWeight: '600' }}>
                                                    {jsonMuyBarato[id].nombre}
                                                </p>
                                                <p style={{ fontSize: '11px', color: 'rgb(0, 1, 0)!important'  , lineHeight: '1', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', textTransform: 'uppercase', margin: '0' }}>
                                                    {jsonMuyBarato[id].puesto}
                                                </p>
                                                <span style={{ fontSize: '67.5%', color: 'rgb(91, 91, 91)', fontFamily: 'Arial, sans-serif', margin: '6px 0 0', display: 'block' }}>
                                                    <img src="https://muybaratomayorista.com.ar/wp-content/uploads/firmas/social/phone.png" alt="phone" style={{ width: '12px', height: '12px', marginRight: '6px', verticalAlign: 'middle' }} />
                                                    {jsonMuyBarato[id].telefono}
                                                </span>
                                                <p style={{ fontSize: '67.5%', color: 'rgb(91, 91, 91)', fontFamily: 'Arial, sans-serif', fontStyle: 'italic', margin: '5px 0 0 0' }}>
                                                    <img src="https://muybaratomayorista.com.ar/wp-content/uploads/firmas/social/email.png" alt="email" style={{ width: '12px', height: '12px', marginRight: '6px', verticalAlign: 'middle' }} />
                                                    <a href="mailto:mgonzalez@muybarato.com" rel="noreferrer" target="_blank" style={{ color: 'rgb(91, 91, 91)', textDecoration: 'none' }}>mgonzalez@muybarato.com</a>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr style={{ maxHeight: '27px' }}>
                                            <td style={{ padding: '0 7px 15px 30%', textAlign: 'left', width: '80%' }}>
                                                <table style={{ width: '100%', marginTop: '2px', padding: '0', border: 'none' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', padding: '0px 6px 2px 0px', margin: '0px' }}>
                                                                <span style={{ fontSize: '71%', color: 'rgb(255, 255, 255) ', fontFamily: 'Arial, sans-serif', fontStyle: 'italic', fontWeight: 'bold' , marginRight: '8px'}}>Seguinos</span>
                                                            
                                                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/muybarato.mayorista" style={{ textDecoration: 'none', color: 'rgb(245, 130, 32)', fontSize: '15px', marginRight: '8px' }}>
                                                                    <img src="https://muybaratomayorista.com.ar/wp-content/uploads/firmas/social/facebook.png" alt="facebook" style={{ width: '0.9em', height: '16px', verticalAlign: 'text-bottom' , marginTop:'3px'}} />
                                                                </a>
                                                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/muybarato.mayorista/?hl=es-la" style={{ textDecoration: 'none', color: 'rgb(245, 130, 32)', fontSize: '16px' }}>
                                                                    <img src="https://muybaratomayorista.com.ar/wp-content/uploads/firmas/social/instagram.png" alt="instagram" style={{ width: '0.9em', height: '16px', verticalAlign: 'text-bottom' }} />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <h1 style={{ color: 'rgb(245, 130, 32)', fontSize: '20px', fontFamily: 'Arial, sans-serif', margin: '0', textAlign: 'center', marginTop: '100px' }}>El usuario no existe o esta mal escrito</h1>
            )}
        </>
    );
}